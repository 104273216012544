<template>
  <div>
    <v-row>
      <v-col sm="8" md="5">
        <s-toolbar label="Historial de Accesos" />
        <v-card>
          <v-col>
            <v-row>
              <v-col>
                <s-date label="Feha de inicio" v-model="begin"></s-date>
              </v-col>
              <v-col>
                <s-date label="Feha de fin" v-model="end"></s-date>
              </v-col>
            </v-row>
            <v-row>
              <v-col><v-btn small color="primary" block @click="reporte()"> Generar Reporte</v-btn></v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import _sAccessControl from "./../../services/Management/ControlAccess";
export default {
  name: "AccessControlReport",
  components: {},
  props: {
    MltID: {
      type: Number,
      default: null,
    },
  },

  data: () => ({ pvs: "1", dialog: true, item: null, end: null, begin: null }),
  methods: {
    reporte() {
      _sAccessControl.historyaccess({ beginDate: this.begin, EndDate: this.end }).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "Reporte de Accesos");
      });
    },
    returnObject(item) {
      this.item = item;
    },
    start() {
      if (this.item != null) this.$emit("start", this.item);
      else this.$fun.alert("Seleccione punto de Vigilancia", "warning");
      //  this.$router.push({ name: 'ACCESS_CONTROL_DETAIL', params: { pv: this.pvs } });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {
    console.log(this.$route);
  },
};
</script>
