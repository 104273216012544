//controlaccess
import Vue from "vue";

const resource = "accesshistory/";

export default {
 
    personaccess(document,usrID,controlAccess,registerHistory) { 
    return Vue.prototype.$http.post(resource + "controlaccess", {},{
      params: { number: document , usrID:usrID,controlaccess:controlAccess,
        registerHistory:registerHistory==undefined?0:registerHistory},
    });
  },
historyaccess(obj) { 
    return Vue.prototype.$http.post(resource + "accesscontrolreport", obj,{
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
}
